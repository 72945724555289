import { template as template_8dfbe06b3a0f4d258c6fddb6aa9e1b79 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8dfbe06b3a0f4d258c6fddb6aa9e1b79(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
