import { template as template_c6d8e4dce0a64ef4bd92d39f504502f6 } from "@ember/template-compiler";
const FKText = template_c6d8e4dce0a64ef4bd92d39f504502f6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
